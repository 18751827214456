import { getAppHostOrigin, getBrokerOsHostOrigin } from '../helpers/utility'

export const APP_HOST = getAppHostOrigin()
export const BROKEROS_HOST = getBrokerOsHostOrigin()
export const ConfigUrl = {
  checkAuthorizationApi: `${BROKEROS_HOST}/agency/v1/agent/is-session-active/`,
  addDeviceFCMApi: `${BROKEROS_HOST}/agency/v1/agent/add-device/`,
  sendOtp: `${BROKEROS_HOST}/agency/v1/agent/send-otp/`,
  validateAgent: `${BROKEROS_HOST}/agency/v1/agent/validate-agent/`,
  createAgent: `${BROKEROS_HOST}/agency/v1/agent/`,
  loginAgent: `${BROKEROS_HOST}/agency/v1/agent/login/`,
  getAgentDetails: `${BROKEROS_HOST}/agency/v1/agent/detail/`,
  getAgentPayLaterCountApi: `${BROKEROS_HOST}/agency/v1/agent/pay-later-policy/`,
  getAgentBadgesApi: `${BROKEROS_HOST}/agency/v1/agent-badge/details/`,
  getProduct: `${BROKEROS_HOST}/agency/v1/product/`,
  getAllProducts: `${BROKEROS_HOST}/agency/v1/product/product-details-list/`,
  logout: `${BROKEROS_HOST}/agency/v1/agent/logout/`,
  generatePaymentToken: `${BROKEROS_HOST}/agency/v1/payment/generate-token/`,
  getPaymentDetail: `${BROKEROS_HOST}/agency/v1/policy/{transactionId}/payment-link/`,
  getVaBankList: `${BROKEROS_HOST}/agency/v1/payment/va-bank-list/`,
  getTransactionList: `${BROKEROS_HOST}/agency/v1/wallet/transaction-list/`,
  getTransactionSummary: `${BROKEROS_HOST}/agency/v1/application/summary/`,
  getPointsTransactionListApi: `${BROKEROS_HOST}/agency/v1/wallet/point-list/`,
  getPaymentStatusApi: transactionId => `${BROKEROS_HOST}/agency/v1/payment/${transactionId}/check-payment-status/`,
  getRegionCode: `${BROKEROS_HOST}/agency/v1/data/vehicle-region-codes/`,
  createPolicy: `${BROKEROS_HOST}/agency/v1/application/create/`,
  getUploadLink: `${BROKEROS_HOST}/agency/v1/application/upload/`,
  customerUploadUrl: `${BROKEROS_HOST}/agency/v1/customer/upload/`,
  getBankList: `${BROKEROS_HOST}/agency/v1/data/bank-list/`,
  requestBankWithdraw: `${BROKEROS_HOST}/agency/v1/wallet/request-bank-withdraw/`,
  getApplicationList: `${BROKEROS_HOST}/agency/v1/application/list/`,
  getPolicyDraft: `${BROKEROS_HOST}/agency/v1/policy-draft/list/`,
  getVariantYear: `${BROKEROS_HOST}/agency/v2/vehicle/years/`,
  getBrandList: year => `${BROKEROS_HOST}/agency/v2/vehicle/${year}/brands/`,
  getModelList: `${BROKEROS_HOST}/agency/v1/vehicle/{brand}/model-list/`,
  getBrandVariant: `${BROKEROS_HOST}/agency/v2/vehicle/{year}/{brand}/brand-variants/`,
  getInvoiceDocuments: (policyId) => `${BROKEROS_HOST}/agency/v1/application/${policyId}/segregated-invoices/`,
  getVerificationDataUrl: `${BROKEROS_HOST}/agency/v1/agent/verification_data/`,
  uploadUserDocUrl: `${BROKEROS_HOST}/agency/v1/agent/upload_document/`,
  submitVerificationDoc: `${BROKEROS_HOST}/agency/v1/agent/submit_document_verify/`,
  editUserEmail: `${BROKEROS_HOST}/agency/v1/agent/edit-email-verify/`,
  sendQuotation: `${BROKEROS_HOST}/agency/v1/application/send-customer-quote/`,
  sendEmailVerifyLink: `${BROKEROS_HOST}/agency/v1/agent/send-verify-link/`,
  convertPointUrl: `${BROKEROS_HOST}/agency/v1/wallet/redempt-point/`,
  serviceChargeApi: `${BROKEROS_HOST}/agency/v1/payment/calculate-service-charge/`,
  withdrawalList: `${BROKEROS_HOST}/agency/v1/wallet/withdraw-list/`,
  getNotifications: `${BROKEROS_HOST}/agency/v1/notification/`,
  getOtherNotifications: `${BROKEROS_HOST}/agency/v1/notification/other-notifications/`,
  remindCustomer: `${BROKEROS_HOST}/agency/v1/notification/remind-customer/`,
  updateReadNotification: `${BROKEROS_HOST}/agency/v1/notification/mark-read/`,
  customerCreatePolicy: `${BROKEROS_HOST}/agency/v1/customer/create/`,
  sendSmsToCustomer: `${BROKEROS_HOST}/agency/v1/application/create-leads/`,
  checkCustomerBlackList: `${BROKEROS_HOST}/agency/v1/product/covid-protection/check-blacklist/`,
  leadListApi: `${BROKEROS_HOST}/agency/v1/leads/`,
  bikeBrandListApi: `${BROKEROS_HOST}/agency/v1/data/bike-brand-v2/il-master-data/`,
  bikeModelListApi: `${BROKEROS_HOST}/agency/v1/data/bike-model-v2/il-master-data/`,
  citiesApi: `${BROKEROS_HOST}/agency/v1/data/lippo_state/il-master-data/`,
  downlineApi: `${BROKEROS_HOST}/agency/v1/agent/downline/`,
  downlineChildApi: `${BROKEROS_HOST}/agency/v1/agent/downline/child/`,
  aggregatorDownlineApi: `${BROKEROS_HOST}/agency/v1/agent/downline/business/`,
  aggregatorDownlineBusinessApi: `${BROKEROS_HOST}/agency/v1/agent/agent/business/`,
  getProofListApi: `${BROKEROS_HOST}/agency/v1/proof/list/`,
  getFilterProofListApi: `${BROKEROS_HOST}/agency/v1/proof/search-proof-list/`,
  proofSubmitApi: `${BROKEROS_HOST}/agency/v1/proof/submit/`,
  instantPayoutBalanceApi: `${BROKEROS_HOST}/agency/v1/proof/instant-payout/`,
  dependentAgentsApi: `${BROKEROS_HOST}/agency/v1/agent/dependents/`,
  getShipMaterial: `${APP_HOST}/api/v1/data/ship-material/`,
  renewalPolicyListApi: `${BROKEROS_HOST}/agency/v1/policy-renewal/policy-renewal-list/`,
  updateAgentAddress: `${BROKEROS_HOST}/agency/v1/agent/address/`,
  getBankListAccounts: `${BROKEROS_HOST}/agency/v1/bank/list-accounts/`,
  updateBankListAccounts: `${BROKEROS_HOST}/agency/v1/bank/add-account/`,
  verifyBankAccounts: `${BROKEROS_HOST}/agency/v1/bank/verify-bank-account/`,
  getAgentGwpApi: `${BROKEROS_HOST}/agency/v1/insurance/agent-paid-gwp/`,

  // functions for api check
  getProofSettlementApi: payload => `${BROKEROS_HOST}/agency/v1/proof/${payload}/settlement/`,
  getProofApi: payload => `${BROKEROS_HOST}/agency/v1/proof/${payload}/proof/`,
  submitProofSettlementApi: payload => `${BROKEROS_HOST}/agency/v1/proof/${payload}/underpayment/`,
  getPackageDetail: payload => `${APP_HOST}/api/v1/package/${payload}/`,
  getPkgDetailV2: payload => `${BROKEROS_HOST}/agency/v1/package/${payload}/details/`,
  getApplicationDetail: payload => `${BROKEROS_HOST}/agency/v1/application/${payload}/detail/`,
  submitApplication: payload => `${BROKEROS_HOST}/agency/v1/application/${payload}/submit/`,
  updateEmail: payload => `${BROKEROS_HOST}/agency/v1/application/${payload}/update-email/`,
  sendPaymentLink: payload => `${BROKEROS_HOST}/agency/v1/application/${payload}/send-payment-link/`,
  resendPaymentLink: payload => `${BROKEROS_HOST}/agency/v1/application/${payload}/resend-payment-link/`,
  getAddonsApi: id => `${BROKEROS_HOST}/agency/v1/product/${id}/add_on/`,
  updateCreatePolicyQuote: token => `${BROKEROS_HOST}/agency/v1/application/${token}/update/`,
  updateCreatePolicyQuoteDiscount: token => `${BROKEROS_HOST}/agency/v1/application/${token}/change-discount/`,
  getCustomerPolicyData: id => `${BROKEROS_HOST}/agency/v1/customer/${id}/detail/`,
  updateCustomerPolicyData: id => `${BROKEROS_HOST}/agency/v1/customer/${id}/initiate_customer_quote/`,
  submitCustomerPolicyData: id => `${BROKEROS_HOST}/agency/v1/customer/${id}/submit/`,
  emailStatusCheckApi: payload => `${BROKEROS_HOST}/agency/v1/agent/${payload}/verification-status/`,
  customerPayingApi: id => `${BROKEROS_HOST}/agency/v1/customer/${id}/customer-paying/`,
  agentPayingApi: id => `${BROKEROS_HOST}/agency/v1/application/${id}/agent-paying/`,
  calculateAddonPremium: pkgCode => `${BROKEROS_HOST}/agency/v1/product/${pkgCode}/calculate_premium/`,
  getWorkShopList: pkgCode => `${BROKEROS_HOST}/agency/v1/product/${pkgCode}/workshop-list/`,
  getVaDetail: transactionId => `${BROKEROS_HOST}/agency/v1/payment/${transactionId}/va-details/`,
  downloadPaymentInstructionsApi: transactionId => `${BROKEROS_HOST}/agency/v1/payment/${transactionId}/download-instructions/`,
  getPackages: productCode => `${BROKEROS_HOST}/agency/v1/product/${productCode}/packages/`,
  policyDataValidationApi: productCode => `${BROKEROS_HOST}/agency/v1/product/${productCode}/data-validation/`,
  updateLead: leadId => `${BROKEROS_HOST}/agency/v1/leads/${leadId}/`,
  openLeadApi: leadId => `${BROKEROS_HOST}/agency/v1/leads/${leadId}/opened/`,
  payLaterApi: policyId => `${BROKEROS_HOST}/agency/v1/application/${policyId}/pay-later/`,
  downloadPdfApi: applicationId => `${BROKEROS_HOST}/agency/v1/application/${applicationId}/download-pdf/`,
  downloadDraftPdfApi: draftId => `${BROKEROS_HOST}/agency/v1/application/${draftId}/download-pdf-draft/`,
  getFwdTncUrl: policyId => `${BROKEROS_HOST}/agency/v1/application/${policyId}/encrypted-fwd-tnc-url/`,
  courierTracking: applicationId => `${BROKEROS_HOST}/agency/v1/application/${applicationId}/hard-copy-tracking/`,
  getPropertyProvinceApi: `${BROKEROS_HOST}/agency/v1/data/province-data/`,
  getOccupationApi: `${APP_HOST}/api/v1/data/occupation-data/`,
  getConstructionApi: `${APP_HOST}/api/v1/data/construction-data/`,
  providerAddonsApi: (pkgCode) => `${BROKEROS_HOST}/agency/v1/product/${pkgCode}/provider-package-map-add-ons/`,
  getAllAddonsApi: (productCode) => `${BROKEROS_HOST}/agency/v1/product/${productCode}/addon-simple/`,
  getPropertyCities: (city) => `${BROKEROS_HOST}/agency/v1/data/cities/${city}/`,
  getInvoiceDetail: (invoiceId) => `${BROKEROS_HOST}/agency/v1/application/${invoiceId}/invoice-receipt/`,
  getProviderSurvey: () => `${BROKEROS_HOST}/agency/v1/application/survey/`,
  convertPayLaterApi: policyId => `${BROKEROS_HOST}/agency/v1/application/${policyId}/convert-pay-later/`,
  getInsuranceDocsApi: referenceId => `${BROKEROS_HOST}/agency/v1/policy/${referenceId}/insurance-documents/`,

  getQuestionStepsApi: `${BROKEROS_HOST}/agency/v1/agent/steps/`,
  getQuestionsApi: step => `${BROKEROS_HOST}/agency/v1/agent/${step}/questions/`,
  submitQuestionAnswersApi: `${BROKEROS_HOST}/agency/v1/agent/answers/`,
  editUpdateQuotationApi: (id) => `${BROKEROS_HOST}/agency/v1/application/${id}/edit-and-update-quotation/`,

  elearningDetailPageEnableApi: `${BROKEROS_HOST}/agency/v1/agent/elearning/detail_page_enable/`,
  getPartnerTokenApi: (partnerCode) => `${BROKEROS_HOST}/agency/v1/agent/${partnerCode}/generate-partner-token/`,

  getStaffListApi: `${APP_HOST}/api/v1/agent/staff/list/`,
  getStaffNameListApi: `${APP_HOST}/api/v1/agent/staff/name/`,
  updateStaffApi: `${APP_HOST}/api/v1/agent/update-staff/`,
  createStaffApi: `${APP_HOST}/api/v1/agent/create-staff/`,
  taxSlabApi: `${BROKEROS_HOST}/agency/v1/agent/tax-info/`,

  getCountryListApi: `${BROKEROS_HOST}/agency/v1/data/country-data/`,
  getAllProviderApi: `${BROKEROS_HOST}/agency/v1/product/provider-list/`,
  createOfflineQuoteApi: `${BROKEROS_HOST}/agency/v1/offline-binder/create/`,
  offlineQuoteListApi: `${BROKEROS_HOST}/agency/v1/offline-binder/list/`,
  offlineQuoteDetailApi: (offlineQuoteId) => `${BROKEROS_HOST}/agency/v1/offline-binder/${offlineQuoteId}/detail`,
  agentAlertsForOfflineInfoApi: `${BROKEROS_HOST}/agency/v1/alert/list/`,
  offlineAlertUpdateApi: (alertId) => `${BROKEROS_HOST}/agency/v1/alert/${alertId}/update/`,
  offlineQuoteUpdateApi: (quoteId) => `${BROKEROS_HOST}/agency/v1/offline-binder/${quoteId}/update/`,
  offlineQuoteVehicleDataUpdateApi: (quoteId) => `${BROKEROS_HOST}/agency/v1/offline-binder/${quoteId}/update-vehicle-data/`,
  offlineQuoteAcceptApi: (quoteId, providerId) => `${BROKEROS_HOST}/agency/v1/offline-binder/${quoteId}/${providerId}/accept-quote/`,
  sendDocumentEmailApi: `${BROKEROS_HOST}/agency/v1/agent/send-template/`,
  convertDocxToPdfApi: (quoteId, providerId) => `${BROKEROS_HOST}/agency/v1/offline-binder/${quoteId}/${providerId}/convert-pdf/`,
  validatePostalCodeApi: `${BROKEROS_HOST}/agency/v1/courier/validate-postal-code/`,
  getCurrencyConversionRate: `${BROKEROS_HOST}/agency/v1/offline-binder/exchange-rate/list/`,

  getRenewalPolicyDetailAPi: (policyId) => `${BROKEROS_HOST}/agency/v1/policy-renewal/${policyId}/policy-renewal-details/`,
  getRenewalQuotationDataAPi: (policyId) => `${BROKEROS_HOST}/agency/v1/policy-renewal/${policyId}/policy-renewal-quotation-data/`,
  submitRenewalPolicyQuoatationDataApi: (policyId) => `${BROKEROS_HOST}/agency/v1/policy-renewal/${policyId}/renewal-policy-detail-data/`,
  getRenewalPolicyDepreciatedDataApi: (policyId) => `${BROKEROS_HOST}/agency/v1/policy-renewal/${policyId}/policy-renewal-depreciated-data/`, // Its not a list api, its provide th
  getRenewalPkgDataAPi: (policyId) => `${BROKEROS_HOST}/agency/v1/policy-renewal/${policyId}/policy-renewal-package-details-data/`,

  getBranchList: providerCode => `${BROKEROS_HOST}/agency/v1/product/${providerCode}/provider-branch/`,
  validateChassisNumberApi: `${BROKEROS_HOST}/agency/v1/application/pre-validate-policy-data/`,

  // Provider Workshop
  getProductProvider: `${BROKEROS_HOST}/agency/v1/product/provider-list/`,
  getProviderWorkshopList: providerCode => `${BROKEROS_HOST}/agency/v1/product/${providerCode}/provider-workshop-list/`,
  createPolicyDraftApi: `${BROKEROS_HOST}/agency/v1/policy-draft/create/`,
  policyDraftListApi: `${BROKEROS_HOST}/agency/v1/policy-draft/list/`,
  policyDraftDeleteApi: draftPolicyId => `${BROKEROS_HOST}/agency/v1/policy-draft/${draftPolicyId}/delete/`,
  policyDeleteApi: policyId => `${BROKEROS_HOST}/agency/v1/application/${policyId}/delete-policy/`,
  policyDraftDetailsApi: draftPolicyId => `${BROKEROS_HOST}/agency/v1/policy-draft/${draftPolicyId}/detail/`,
  draftPolicyUpdateApi: draftPolicyId => `${BROKEROS_HOST}/agency/v1/policy-draft/${draftPolicyId}/update/`,
  completeDraftQuoteApi: draftPolicyId => `${BROKEROS_HOST}/agency/v1/policy-draft/${draftPolicyId}/complete-draft/`,
  draftQuoteSearchApi: `${BROKEROS_HOST}/agency/v1/policy-draft/search/`,
  markPolicyQuotationCreatedApi: (policyId) => `${APP_HOST}/api/v1/policy-renewal/${policyId}/mark-policy-quotation-created/`,

  claimDetailsApi: claimId => `${BROKEROS_HOST}/agency/v1/claim/${claimId}/details/`,
  claimsListApi: `${BROKEROS_HOST}/agency/v1/claim/list/`,

  giveFeedbackFormApi: (feedbackRequestId, rating) => `${BROKEROS_HOST}/agency/v1/claim/${feedbackRequestId}/get-form/?rating=${rating || 1}`,
  submitGiveFeedbackFormApi: (feedbackRequestId) => `${BROKEROS_HOST}/agency/v1/claim/${feedbackRequestId}/submit-form/`,

  shareDraftQuoteApi: `${BROKEROS_HOST}/agency/v1/application/send-customer-draft/`,
  completeCustomerDraftApi: id => `${BROKEROS_HOST}/agency/v1/customer-draft/${id}/complete-draft/`,
  updateCustomerDraftApi: id => `${BROKEROS_HOST}/agency/v1/customer-draft/${id}/update/`,
  customerQuoteDraftDetailsApi: id => `${BROKEROS_HOST}/agency/v1/customer-draft/${id}/detail/?show_agent=1`,
  updateProfile: `${BROKEROS_HOST}/agency/v1/agent/update-detail/`,
  updatePhoneNumber: `${BROKEROS_HOST}/agency/v1/agent/verify-and-update-phone/`,
  resendOtp: `${BROKEROS_HOST}/agency/v1/agent/resend-otp/`,
  updateAgentPhoneNo: `${BROKEROS_HOST}/agency/v1/agent/change-phone-no/`,
  getInstalmentDetailsApi: id => `${BROKEROS_HOST}/agency/v1/application/${id}/instalment-details/`,
  deleteNotificationApi: `${BROKEROS_HOST}/agency/v1/notification/delete/`,
  getNotificationDetailsApi: `${BROKEROS_HOST}/agency/v1/notification/detail/`,
  getEndorsementReqFormApi: `${BROKEROS_HOST}/agency/v1/endorsement-request/get-form/`,
  createEndorsementReqApi: `${BROKEROS_HOST}/agency/v1/endorsement-request/`,
  getOfflineProductListApi: `${BROKEROS_HOST}/agency/v1/offline-form/product-list/`,
  getOfflineProductFormApi: `${BROKEROS_HOST}/agency/v1/offline-form/product-form/`,

  getTravelRegionApi: `${BROKEROS_HOST}/agency/v1/data/get-travel-regions/`,
  createTravelDraftApi: `${BROKEROS_HOST}/agency/v1/policy-draft/create/`,
  // travel packegae
  getTravelDraft: (id) => `${BROKEROS_HOST}/agency/v1/policy-draft/${id}/detail/`,
  //Travel Api's
  getInsuredPackageListApi: `${BROKEROS_HOST}/agency/v1/product/travel-protection/packages/`,
  getInsuredPackageDetailApi: (package_code) => `${BROKEROS_HOST}/agency/v1/package/${package_code}/details/`,
  getTravelCompleteDraftApi: (id) => `${BROKEROS_HOST}/agency/v1/policy-draft/${id}/complete-draft/`
}
