import styled, { createGlobalStyle } from 'styled-components'

export const DisplayFlexRCC = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const DisplayFlexCCC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  justify-content: center;
`

export const DisplayFlexRCSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridColumn || '1fr'};
  grid-gap: ${props => props.gridGap || '0'};
  align-items: ${props => props.align || 'start'}
`

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};;
  width: ${props => props.width || 'auto'};
  align-items: ${props => props.align || 'start'};
  justify-content: ${props => props.justify || 'start'};
  background-color: ${props => props.bgColor || 'unset'};
  flex-grow: ${props => props.flexGrow || 'unset'};
`

export const BottomActionContainerStyle = styled(DisplayFlexRCC)`
  background-color: ${props => props.bgColor || props.theme.white};
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'center'};
  padding: ${props => props.padding || '20px'};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: ${props => props.position || 'fixed'};
  bottom:  ${props => props.bottom || '0'};
  left: 0;
  right: 0;
  max-width: 450px;
  margin: 0 auto;

  .button-wrapper, .inner-btn-box {
    padding: 20px;
    flex: 1;
  }

  .btn-wrapper {
    padding: 0.5rem 1rem;
    flex: 1;
    border-top: 1px solid ${props => props.theme.Gray90};
  }

  .btn-margin {
    margin: 1rem;
  }

  @media only screen and (max-width: 823px) and (orientation: landscape){
    padding: ${props => props.padding || '10%'};
    max-width: 100%;
    margin: 0;
  }
`

export const FlatBottomActionStyle = styled(Row)`
  background-color: ${props => props.bgColor || props.theme.white};
  position: ${props => props.position || 'fixed'};
  bottom:  ${props => props.bottom || '0'};
  padding: 16px 0;
  border-top: 1px solid ${props => props.theme.Gray90};
  left: 0;
  right: 0;
  max-width: 450px;
  margin: 0 auto;

  @media only screen and (max-width: 823px) and (orientation: landscape){
    max-width: 100%;
    margin: 0;
  }

  @media only screen and (max-width: 823px) {
    /* For mobile phones: */
    max-width: 100%;  
  }
`

export const RequiredStar = styled.span`
  color: ${props => props.color || props.theme.errorDark};
  font-size: ${props => props.size || 'normal'};
  vertical-align: ${props => props.verticalAlign || 'initial'};
`

export const Error = styled.span`
  color: ${props => props.theme.vibrantRed};
  font-size: 0.7em;
  font-weight: 700;
`

export const DisplayCircleIcon = styled(DisplayFlexRCC)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  border-radius: 50%;
  color: ${props => props.color || 'transparent'};
  background-color: ${props => props.bgColor || props.theme.primary};

  img {
    height: 10px;
  }
`

export const EditCircleIcon = styled(DisplayFlexRCC)`
  width: ${props => props.width || '30px'};
  height: ${props => props.width || '30px'};
  border-radius: 50%;
  color: ${props => props.color || props.theme.darkerGold};
  border-radius: 50%;
  font-size: ${props => props.size || '14px'};
  margin-left: ${props => props.marginLeft || '10px'};
  font-weight: 800;
  background-color: ${props => props.bgColor || props.theme.primary};
`

export const Hr = styled.div`
  height: 4px;
  border-radius: 2px;
  background-color: ${props => props.bgColor || props.theme.primary};
  width: 100%;
  margin: 20px 0;
`

export const FlatBottomBtn = styled.button`
  background-color: ${props => props.bgColor || props.theme.white};
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'center'};
  padding: ${props => props.padding || '20px'};
  font-size: 14px;
  color: ${props => props.color || props.theme.black};
  font-weight: 800;
  position: ${props => props.position || 'fixed'};
  border: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  &:disabled {
    background-color: ${props => props.theme.fontSemiGrey};
    pointer-events: none;
    color: ${props => props.theme.white};
  }

  @media only screen and (max-width: 823px) and (orientation: landscape) {
    max-width: 100%;
    margin: 0;
  }
`

export const PolicyApplicationFormWrapper = styled.div`
  padding-top: 6%;
  position: relative;

  .form-container {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 4%;
  }
  hr {
    border: 1px solid ${props => props.theme.grey};
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: -6%;
    margin-right: -6%;
  }
  .photos-container {
    display: flex;
    justify-content: space-between;
  }
  .small-img-container {
    width: 148px;
    height: 100px !important;
    max-width: 100px;
    min-width: 148px !important;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .upload-row {
    width: 80%;
  }
  .image-display {
    height: 124px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 164px;
    /* margin: 12px; */
    border: 1px solid ${props => props.theme.grey};
    border-radius: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .accessory-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer {
    background-color: ${props => props.theme.white};
    padding: 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: 30px;
  }
  .footer-padding {
    padding: 20px;
  }
`

export const MitraCard = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  padding: ${props => props.padding || '12px'};
  border-radius: 8px;
  width: ${props => props.width || '80%'};
  margin: auto;
  margin-bottom: ${props => props.bottom || '20px'};
  margin-top: ${props => props.marginTop || '10px'};
`

export const DummyPlaceholder = styled.div`
  background-color: #f3f3f3;
  background: linear-gradient(to right,#f3f3f3 5%,#eee 20%,#f3f3f3 35%);
  animation: placeholderShimmer 2s linear 0s infinite normal forwards;

  .parent {
    position: relative;
    height: 13px;

    &:after {
      position: absolute;
      right: 0;
      content: '';
      background: #fff;
      height: 14px;
      width: 70%;
    }
  }

  @keyframes placeholderShimmer {
    0% {background-position: -500px 0;};
    100% {background-position: 500px 0;};
  }
`
export const ErrorCrossStyle = styled(DisplayFlexRCC)`
  width: ${props => props.width || '30px'};
  height: ${props => props.height || '30px'};
  border-radius: 50%;
  color: ${props => props.theme.white};
  background-color: ${props => props.bg || props.theme.errorDark};
  position: absolute;
  top: 0px;
  right: ${props => props.right || '10px'};
`

export const SectionStyle = styled.div`
  padding: ${props => props.padTop || '0px'} 20px ${props => props.padBtm || '20px'};
`

export const BaseStyles = createGlobalStyle`
  .text-normal {
    font-size: 15px;  
  }
  .text-small {
    font-size: 14px;
    line-height: 1.33;
    /* color: ${p => p.theme.darkCharcoal} */
  }
  .text-x-small {
    font-size: 13px;
    line-height: 1.33;
  }
  .text-xx-small {
    font-size: 11px;
  }
  .text-xxx-small_8 {
    font-size: 8px;
    letter-spacing: 0.4px;
  }
  .text-xxx-small {
    font-size: 9px;
    letter-spacing: 0.6px;
  }
  .heading-medium {
    font-size: 24px;
  }
  .text-dark {
    color: ${p => p.theme.regular}
  }
  .text-dark-gray {
    color: ${p => p.theme.fontDarkerGrey};
  }
  .text-gray {
    color: ${p => p.theme.fontGrey};
  }
  .text-bold {
    font-weight: bold;
  }
  .text-semi-bold {
    font-weight: 600;
  }
  .text-extra-bold {
    font-weight: 800;
  }
  .text-white {
    color: white;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .flex-container {
    display: flex;
  }
  .direction-column {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .justify-space-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-self-center {
    justify-self: center;
  }
  .align-center {
    align-items: center;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .p-16 {
    padding: 16px;
  }
  .pt-5 {
    padding-top: 5px; 
  }
  .pt-16 {
    padding-top: 16px; 
  }
  .mr-5 {
    margin-right: 5px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .bg-gray {
    background-color: ${p => p.theme.fontLightGrey};
  }
  .bg-primary {
    background-color: ${p => p.theme.primary};
  } 
`

export const PageSection = styled.section`
  padding-top: ${props => props.padTop || '106px'};
`

export const SectionScroll = styled.div`
  max-height: calc(100vh - 120px);
  overflow: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: ${props => props.padBtm || 'auto'}; ;
  padding-top: ${props => props.padTop || '106px'}; 
`
