import { useState, useEffect } from 'react'
import { BottomActionSheetStyle } from './index.style'
import PropTypes from 'prop-types'
import { CustomText } from '..'
import { AgentColorPallette } from '../../configs/colorPallete'
import { CLOSE_GREY } from '../../images'
import Loader from '../Loader/index'
import docSlider from '../../helpers/docSlider'

function BottomActionSheet ({
  children, title, className, show, headerTextAlign, backGroundColor,
  onCloseSheet = () => {}, showHeader, bottom, zIndex,
  loader, modalClose = true
}) {
  const [showAnimation, setAnimation] = useState(false)

  useEffect(() => {
    if (show) {
      docSlider.enable(false)
      setTimeout(() => {
        setAnimation(true)
      }, 100)
    } else if (showAnimation) {
      docSlider.enable(true)
      setAnimation(false)
    }
  }, [show, showAnimation])

  return (
    <BottomActionSheetStyle
      show={show}
      className={className}
      onClick={modalClose ? onCloseSheet : () => {}}
      headerTextAlign={headerTextAlign}
      backGroundColor={backGroundColor}
      bottom={bottom}
      zIndex={zIndex}
      id='bottomSheet'
    >
      {
        (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`bottom-sheet-container ${show && showAnimation && 'animation-show'}`}
          >
            <Loader visible={loader}>
              {
                showHeader && (
                  <div className='help-agent-header'>
                    <CustomText
                      color={AgentColorPallette.black}
                      size='1em'
                      weight={600}
                      className='help-title'
                    >
                      {title}
                    </CustomText>
                    <div className='close-icon' onClick={onCloseSheet}>
                      <img src={CLOSE_GREY} alt='close' />
                    </div>
                  </div>
                )
              }
              {children}
            </Loader>
          </div>
        )
      }
    </BottomActionSheetStyle>
  )
}

BottomActionSheet.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  show: PropTypes.bool,
  onCloseSheet: PropTypes.func
}

export default BottomActionSheet
