export const AgentColorPallette = {
  darkPurple: '#664a92',
  purple: '#303f9f',
  blueGreen: '#0097a7',
  blackGrey: '#212121',
  yellow: '#EAC400',
  darkPink: '#c2185b',
  orangePink: '#FE4639',
  lighterPink: '#ffcdd2',
  golden: '#CBAA01',
  primary: '#fac800',
  darkYellow: '#fac800',
  pureYellow: '#e6be00',
  secondary: '#6edce6',
  regular: '#333333',
  uiGold: '#836c15',
  goldDarker: '#856f00',
  goldenText: '#947827',
  golderLigher: '#fdefa6',
  hoverTextColor: '#50a0aa',
  font: '#ffffff',
  fontSecondary: '#777777',
  platinum: '#e3e5e8',
  fontGrey: '#999999',
  Gray90: '#e5e5e5',
  devysGrey: '#555555',
  fontLightGrey: '#f5f5f5',
  lightGreen: '#d3e8d1',
  snowGray: '#f9f9f9',
  dustyGrey: '#efefef',
  darkMaroon: '#7E0A0A',
  maroonRed: '#a80d0d',
  fontSemiGrey: '#aaaaaa',
  blood: '#a80d0d',
  lightSilver: '#d2d2d2',
  fontDarkGrey: '#967800',
  fontDarkerGrey: '#666666',
  pattensBlue: '#d2e6fa',
  blue: '#008cd2',
  darkBlue: '#0080c7',
  darkerBlue: '#0082be',
  cyanBlue: '#325a96',
  aqua: '#b2dfdb',
  bannerBlue: '#3567af',
  borderGrey: '#cccccc',
  error: '#fa9696',
  errorDark: '#fa6464',
  bloodRed: '#961a1a',
  errorText: '#963c3c',
  black: '#000000',
  lightBlack: '#000000a3',
  lightBlack25: '#00000040',
  blackTransparent: '#231f1f3d',
  darkCharcoal: '#333333',
  white: '#ffffff',
  wine: '#8c2828',
  saturatedGrey: '#dddddd',
  green: '#64c800',
  greenDarker: '#51a348',
  limeGreen: '#32b45a',
  lightYellow: '#ffffe1',
  grey: '#e6e6e6',
  mustard: '#dcb400',
  lightBlue: '#0078be',
  grey_eee: '#eee',
  orange: '#fab400',
  lightPink: '#ffd7d7',
  otpBackground: '#86878c',
  darkGold: '#aa8c00',
  lightGold: '#cab44d',
  darkerGold: '#967800',
  madras: '#544300',
  shineLightGold: '#c1ac4b',
  shineGold: '#aa8c28',
  bronze: '#b47850',
  lightBronze: '#dcb496',
  lightOrange: '#ffe8ae',
  warning: '#ef6c00',
  buddhaGold: '#b49600',
  deepSkyBlue: '#00d2fa',
  blueDefault: '#0096fa',
  vibrantRed: '#c80000',
  orangeMangoTango: '#db7600',
  lemon: '#faf0c8',
  irishGreen: '#009632',
  crusieGreen: '#C8F0DC',
  speechRed: '#C80000',
  lightRed: '#F0C8C8',
  paleYellow: '#FAF0C8',
  transparent: '#ffffff0',
  darkBrown: '#5d4037',
  darkRed: '#FA3C46',
  skyBlue: '#00B4FA',
  darkCyanBlue: '#0064C8',
  darkerRed: '#DC0000',
  lighterRed: '#FADCDC',
  goldYellow: '#B4A000'
}
